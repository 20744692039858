import axios from "axios";

export class ReporteduserService {
  async fetchAll() {
    let temp = (await axios.get("/report-post")).data;
    let userArr = [];
    let unique = {};

    temp.forEach((item) => {
      const userEmail = item.userId.email;

      if (!userArr.some((user) => user.email === userEmail)) {
        unique[userEmail] = {
          ...item.userId,
          user_id: item.userId._id,
          postCount: 1,
        };
        userArr.push(unique[userEmail]);
      } else {
        unique[userEmail] = {
          ...item.userId,
          user_id: item.userId._id,
          postCount: unique[userEmail].postCount + 1,
        };
      }
    });
    return Object.values(unique);
  }

  async getPosts(id) {
    let temp = (await axios.get("/report-post")).data;
    return temp.reduce((accumulator, item) => {
      if (item.userId._id === id) {
        accumulator.push({ ...item, mainId: item._id });
      }
      return accumulator;
    }, []);
  }

  async fetchOne(id, postId) {
    let temp = (await axios.get("/report-post")).data;
    let reporters = temp
      .filter((item) => item.postId?._id === postId)
      .map((item) => ({
        ...item.reportBy,
        reportReason: item.reportReason,
      }));
    let post = temp.filter((item) => item._id === id);
    return { post: post[0], reporters };
  }

  async update(user) {
    return (await axios.patch(`/persons/update/${user._id}/for-admin`, user))
      .data;
  }

  async delete(user) {
    return (await axios.delete("/persons/" + user._id)).data;
  }
}
