<template>
  <div>
    <data-table
      :loader="loadData"
      :title="
        getUser() && getUser().role === 'ADMIN'
          ? 'Merchant Messages'
          : 'Notifications'
      "
      :headers="getUser() && getUser().role === 'ADMIN' ? headers : headers2"
      :view-handler="viewItem"
      @done="$router.back()"
      :key="dataTableKey"
    >
      <template #message="{ item }">
        {{
          item.message.length > 100
            ? item.message.substring(0, 100 - 3) + "..."
            : item.message
        }}
      </template>

      <template #primary-action>
        <v-btn
          color="primary"
          elevation="0"
          outlined
          @click="addNew"
          v-if="getUser() && getUser().role === 'ADMIN'"
        >
          Send Message
        </v-btn>
      </template>

      <template #isRead="{ item }">
        <v-icon
          v-if="item.isRead"
          color="green"
          small
          class="mr-2"
          @click.stop="viewItem(item)"
        >
          mdi-check-circle
        </v-icon>
        <v-icon
          v-else
          color="red"
          small
          class="mr-2"
          @click.stop="viewItem(item)"
        >
          mdi-circle
        </v-icon>
      </template>

      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </data-table>

    <v-dialog v-model="showMessageDialog" max-width="800px">
      <v-card>
        <v-card-title class="headline">Notification Message</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="message"
            outlined
            readonly
            :rows="message.length > 450 ? 10 : 5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showMessageDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { MerchantMessagesService } from "../../services/merchant-messages-service";
import { MerchantsService } from "../../services/merchant-service";
import DataTable from "../../components/DataTable";
import { getUser } from "@/utils/local";
import dayjs from "dayjs";

export default {
  components: { DataTable },

  data: () => ({
    dataTableKey: 0,
    user: getUser(),
    showMessageDialog: false,
    message: "",
    items: [],
    service: new MerchantMessagesService(),
    merchantService: new MerchantsService(),
    headers: [
      {
        text: "Merchant",
        value: "merchantId",
        sortable: true,
      },
      {
        text: "Message",
        value: "message",
        sortable: true,
      },
      {
        text: "Seen",
        value: "isRead",
        sortable: true,
      },
      {
        text: "Created At",
        value: "createdAt",
        sortable: true,
      },
    ],

    headers2: [
      {
        text: "Message",
        value: "message",
        sortable: true,
      },
      {
        text: "Created At",
        value: "createdAt",
        sortable: true,
      },
    ],
  }),
  methods: {
    getUser,

    formatDate(date) {
      return dayjs(date).format("D MMM YYYY");
    },
    addNew() {
      this.$router.push("/merchant-message");
    },

    async viewItem(item) {
      this.message = item.message;
      this.showMessageDialog = true;

      console.log(item);

      if (this.user.role === "MERCHANT" && item.isRead === false) {
        await this.service.update(item._id, { isRead: true });
        this.dataTableKey++;
      }
    },

    async deleteItem(item) {
      await this.service.delete(item);
    },

    async loadData() {
      if (this.getUser() && this.getUser().role === "ADMIN") {
        let items = await this.service.fetchAll();

        items = await Promise.all(
          items.map(async (item) => {
            const merchant = await this.merchantService.fetchOne(
              item.merchantId
            );
            return {
              ...item,
              merchantId: merchant.restaurantName,
            };
          })
        );

        return items;
      } else {
        let merchants = await this.merchantService.fetchAll();
        merchants = merchants.filter(
          (merchant) =>
            merchant.userId && merchant.userId._id === this.getUser()._id
        );
        let items = await this.service.fetchAllByMerchant(merchants[0]._id);

        return items;
      }
    },
  },
};
</script>
