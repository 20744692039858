<template>
  <div>
    <div>
      <data-table
        :loader="loadData"
        :headers="headers"
        :allow-add="false"
        @done="$router.back()"
        :delete-handler="null"
        :edit-handler="null"
        :view-handler="
          userScopes.includes('users:view') || user.role === 'ADMIN'
            ? view
            : null
        "
      >
        <template #firstname="{ item }">
          {{ item.firstname }}
        </template>

        <template #isBlocked="{ item }">
          <v-checkbox
            v-model="item.isBlocked"
            :value="item.isBlocked"
            @change="updateUser(item)"
          ></v-checkbox>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import { UsersService } from "@/services/user-service";
import DataTable from "../../components/DataTable";
import { getUserScopes } from "../../utils/local";
import { getUser } from "@/utils/local";

export default {
  components: { DataTable },

  data: () => ({
    user: getUser(),
    items: [],
    users_service: new UsersService(),
    userScopes: getUserScopes(),

    headers: [
      {
        text: "First Name",
        value: "firstname",
        sortable: true,
      },
      {
        text: "Surname",
        value: "surname",
        sortable: true,
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },
      {
        text: "Account Blocked",
        value: "isBlocked",
        sortable: true,
      },
      {
        text: "Account Type",
        value: "accountType",
        sortable: true,
      },
      {
        text: "Account Holder Type",
        value: "accountHolderType",
        sortable: true,
      },
    ],
  }),

  methods: {
    view(item) {
      this.$router.push(`/user-details?id=${item._id}`);
    },

    async updateUser(item) {
      let status = item.isBlocked ? true : false;
      await this.users_service.blockUser(item._id, status);
      this.$toast.success("User updated successfully");
    },

    async deleteUser(item) {
      await this.users_service.delete(item);
    },

    async loadData() {
      return await this.users_service.fetchAll();
    },
  },
};
</script>
