<template>
  <SimpleForm
    :is-edit="false"
    :disabled="false"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row class="span-2" no-gutters>
      <p class="form__title" style="width: 90%">
        Update Password
      </p>
    </v-row>
    <div class="span-2">
      <v-text-field
        id="password"
        class="mb-10"
        v-model="password.oldPassword"
        :rules="[required('Current Password must be provided')]"
        hide-details="auto"
        label="Current Password"
        :type="showPassword ? 'text' : 'password'"
        outlined
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      />
      <v-text-field
        id="password"
        class="mb-10"
        v-model="password.newPassword"
        :rules="[required('New Password must be provided')]"
        hide-details="auto"
        label="New Password"
        :type="showPassword ? 'text' : 'password'"
        outlined
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      />
      <v-text-field
        id="password"
        class="mb-10"
        v-model="password.confirmPassword"
        :rules="[required('Confirm Password must be provided')]"
        hide-details="auto"
        label="Confirm Password"
        :type="showPassword ? 'text' : 'password'"
        outlined
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      />
    </div>
    <loading-dialog v-model="loading" message="Fetching Voucher Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from "../../components/Form";
import LoadingDialog from "../../components/LoadingDialog";
import { email, required } from "@/utils/validators";
import { MerchantsService } from "@/services/merchant-service";

export default {
  name: "Form",
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    showPassword: false,
    password:{
      oldPassword: "",
      newPassword: "",
      confirmPassword:""
    },
    loading: false,
    merchantsService: new MerchantsService()
  }),

  methods: {
    required,
    email,
    async submit(context) {
      context.changeLoadingMessage("Updating Password");
      if(this.password.newPassword !== this.password.confirmPassword){
        this.$toast.error("Passwords do not match");
        return
      }
      try {
        await this.merchantsService.updatePassword(this.password);
        return true;
      } catch (e) {
        context.reportError({
          title: "Failed to update Password",
          description: e.response
            ? e.response.data.message
            : "Something went wrong!"
        });

        return false;
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
