<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row class="span-2" no-gutters>
      <v-btn @click="$router.back()" elevation="0">
        <v-icon>mdi-arrow-left</v-icon></v-btn
      >

      <p class="form__title" style="width: 90%">
        {{ isEdit ? "Update Merchant Message" : "Send Merchant Message" }}
      </p>
    </v-row>

    <v-select
      v-model="message.merchantId"
      :items="merchants"
      :rules="[required()]"
      label="Merchant"
      item-text="restaurantName"
      item-value="_id"
      outlined
      dense
      required
      class="span-2"
    />

    <v-textarea
      v-model="message.message"
      :rules="[required()]"
      label="Message"
      outlined
      dense
      required
      class="span-2"
    ></v-textarea>

    <loading-dialog v-model="loading" message="Fetching Quote Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from "../../components/Form";
import { MerchantMessagesService } from "../../services/merchant-messages-service";
import { MerchantsService } from "../../services/merchant-service";
import LoadingDialog from "../../components/LoadingDialog";
import { required } from "../../utils/validators";

export default {
  name: "AdminForm",
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    service: new MerchantMessagesService(),
    merchantService: new MerchantsService(),

    // only for edit
    disabled: false,
    merchants: [],
    message: {
      message: "",
      merchantId: "",
    },
  }),

  async mounted() {
    // this.loadQuote();

    this.merchants = await this.merchantService.fetchAll();
  },

  methods: {
    required,

    // async loadQuote() {
    //   if (!this.$route.query.id) return;
    //   this.isEdit = true;
    //   this.loading = true;
    //   this.quote = await this.quote_service.fetchOne(this.$route.query.id);
    //   this.loading = false;
    // },
    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage("Updating Merchant Message");

        // try {
        //   //updating quote
        //   await this.quote_service.update(this.quote);
        //   return true;
        // } catch (e) {
        //   context.reportError({
        //     title: "Error while updating Quote",
        //     description: e.response
        //       ? e.response.data.message
        //       : "Something went wrong!",
        //   });
        //   return false;
        // }
      } else {
        context.changeLoadingMessage("Sending Merchant Message");
        try {
          await this.service.create(this.message);
          return true;
        } catch (e) {
          context.reportError({
            title: "Error while creating Quote",
            description: e.response
              ? e.response.data.message
              : "Something went wrong!",
          });

          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
