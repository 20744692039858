<template>
  <data-table
    :loader="loadData"
    :headers="headers"
    @done="$router.back()"
    :view-handler="getUser() && getUser().role === 'ADMIN' ? view : null"
  >
    <template #userName="{ item }">
      {{ item.userId.username }}
    </template>

    <template #email="{ item }">
      {{ item.userId.email }}
    </template>

    <template #title="{ item }">
      {{ item.cardId.title }}
    </template>

    <template #type="{ item }">
      {{ item.cardId.type }}
    </template>
  </data-table>
</template>

<script>
import { MerchantsService } from "../../services/merchant-service";
import DataTable from "../../components/DataTable";
import { getUser } from "../../utils/local";

export default {
  components: { DataTable },

  mounted() {
    this.loadData;
  },

  data: () => ({
    items: [],
    merchant_service: new MerchantsService(),

    merchants: [],
    merchant: {},

    headers: [
      {
        text: "User Name",
        value: "userName",
        sortable: true,
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Name",
        value: "title",
        sortable: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: true,
      },
      {
        text: "Reward",
        value: "reward",
        sortable: true,
      },
    ],
  }),

  methods: {
    getUser,

    view(item) {
      this.$router.push(
        `/stamp-card-details?restaurantId=${item.restaurantId._id}&stampCardId=${item.cardId._id}`
      );
    },

    async loadData() {
      let data = await this.merchant_service.fetchAllStampCards();
      return data;
    },
  },
};
</script>
