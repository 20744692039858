import CustumNotificationView from "./CustumNotificationView";
import { getUser } from "../../utils/local";

export const CustumNotificationRoutes = [
  getUser()?.role === "ADMIN"
    ? {
        name: "CustumNotification",
        path: "/custom-notification",
        title: "Custum Notification",
        component: CustumNotificationView,
      }
    : {
        path: "/custom -notification",
        redirect: "/no-permission",
      },
];

export const CustumNotificationRouter = CustumNotificationRoutes.filter(
  function (x) {
    return x !== null;
  }
);
