<template>
  <SimpleForm
    :is-edit="isEdit"
    :disabled="disabled"
    :onSubmit="submit"
    @done="$router.back()"
  >
    <v-row class="span-2" no-gutters>
      <v-btn @click="$router.go(-1)" elevation="0">
        <v-icon class="v-btn__pre-icon">mdi-arrow-left</v-icon></v-btn
      >

      <p class="form__title" style="width: 90%">
        {{ isEdit ? "Update Stamp Card" : "Add New Stamp Card" }}
      </p>
    </v-row>

    <v-text-field
      v-model="stampCard.title"
      :rules="[required('Name must be provided')]"
      class="span-2"
      label="Name"
      outlined
      color="#111827"
    />

    <v-textarea
      v-model="stampCard.description"
      :rules="[required('Description must be provided')]"
      class="span-2"
      label="Description"
      outlined
      color="#111827"
    />

    <v-select
      v-model="stampCard.totalPoints"
      :rules="[required('Total Points must be provided')]"
      class="span-2"
      label="Total Points"
      outlined
      color="#111827"
      :items="[5, 10, 15, 20, 25, 30]"
    ></v-select>

    <v-text-field
      v-model="stampCard.reward"
      :rules="[required('Reward must be provided')]"
      class="span-2"
      label="Reward"
      outlined
      color="#111827"
    />

    <v-text-field
      v-if="!isEdit"
      v-model.number="stampCard.timeDurationInDays"
      :rules="[required('Time Duration must be provided')]"
      class="span-2"
      label="Time Duration In Days"
      outlined
      color="#111827"
      type="number"
    />

    <v-select
      v-if="!isEdit"
      v-model="stampCard.type"
      :items="stampCardTypes"
      :rules="[required('Type must be provided')]"
      class="span-2"
      label="Type"
      outlined
      color="#111827"
    />

    <loading-dialog v-model="loading" message="Fetching Stamp Card Data" />
  </SimpleForm>
</template>

<script>
import SimpleForm from "../../components/Form";
import { MerchantsService } from "../../services/merchant-service";
import LoadingDialog from "../../components/LoadingDialog";
import { required } from "@/utils/validators";

export default {
  name: "Form",
  components: { LoadingDialog, SimpleForm },

  data: () => ({
    isEdit: false,
    loading: false,
    merchantsService: new MerchantsService(),

    // only for edit
    disabled: false,
    stampCardTypes: ["STUDENT", "NON-STUDENT", "BOTH"],

    stampCard: {
      restaurantId: "",
      title: "",
      description: "",
      totalPoints: 0,
      type: "",
      timeDurationInDays: 0,
      reward: "",
    },
  }),

  mounted() {
    this.loadStampCard();
  },

  methods: {
    required,

    async loadStampCard() {
      if (this.$route.query.id) {
        this.stampCard.restaurantId = this.$route.query.id;
      }
      if (!this.$route.query.restaurantId) return;
      this.isEdit = true;
      this.loading = true;

      this.stampCard = await this.merchantsService.fetchOneStampCard(
        this.$route.query.stampCardId
      );

      this.loading = false;
    },

    async submit(context) {
      if (this.isEdit) {
        context.changeLoadingMessage("Updating Stamp Card");

        try {
          this.merchantsService.updateStampCard({
            cardId: this.$route.query.stampCardId,
            title: this.stampCard.title,
            totalPoints: this.stampCard.totalPoints,
            reward: this.stampCard.reward,
          });
          return true;
        } catch (e) {
          context.reportError({
            title: "Error while updating Stamp Card",
            description: e.response
              ? e.response.data.message
              : "Something went wrong!",
          });
          return false;
        }
      } else {
        context.changeLoadingMessage("Creating Stamp Card");
        try {
          await this.merchantsService.createStampCard(this.stampCard);
          return true;
        } catch (e) {
          context.reportError({
            title: "Failed to create Stamp Card",
            description: e.response
              ? e.response.data.message
              : "Something went wrong!",
          });

          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>
