<template>
  <v-card :class="[hasElevation ? 'elevation-2' : 'elevation-0']">
    <slot name="tabs" />
    <v-card-title
      :class="`d-flex ${
        $vuetify.breakpoint.width > 800 ? 'flex-row' : 'flex-column'
      } justify-start align-start`"
      :style="$vuetify.breakpoint.width > 800 ? '' : 'gap: 10px'"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2" v-if="allowBack" @click="$router.go(-1)"
          >mdi-arrow-left
        </v-icon>

        <span class="data-table__header">{{ title }}</span>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.width > 800" />

      <div
        style="margin-right: 20px"
        :style="[
          $vuetify.breakpoint.width < 800
            ? { width: 100 + '%' }
            : { width: 400 + 'px' },
        ]"
      >
        <v-text-field
          v-if="allowSearch"
          v-model="search"
          label="Search Anything"
          append-icon="mdi-magnify"
          outlined
          style="font-family: sans-serif"
          dense
        />
      </div>
      <slot
        name="primary-action"
        :class="[$vuetify.breakpoint.width > 800 ? 'my-2' : '']"
      />

      <v-btn
        :class="[$vuetify.breakpoint.width > 800 ? 'mr-2' : '']"
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        v-if="addBenefits"
        color="primary"
        elevation="0"
        @click="$emit('add-benefits')"
      >
        Update Event Benefits
      </v-btn>

      <!-- deposit money -->
      <v-btn
        :class="[$vuetify.breakpoint.width > 800 ? 'mr-2' : '']"
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        v-if="allowDeposit"
        color="primary"
        elevation="0"
        @click="$emit('add-deposit')"
      >
        Deposit Money
      </v-btn>

      <!-- generate invoice -->
      <v-btn
        :class="[$vuetify.breakpoint.width > 800 ? 'mr-2' : '']"
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        v-if="allowInvoice"
        color="primary"
        elevation="0"
        @click="$emit('generate-invoice')"
      >
        Generate Invoice
      </v-btn>

      <v-btn
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        v-if="allowAdd"
        color="primary"
        elevation="0"
        @click="$emit('add-new')"
      >
        <v-icon class="v-btn__pre-icon" small>mdi-plus</v-icon>&nbsp; Add New
      </v-btn>

      <v-btn
        :style="[$vuetify.breakpoint.width < 800 ? { width: 100 + '%' } : '']"
        :class="[$vuetify.breakpoint.width > 800 ? 'ml-2' : '']"
        color="primary"
        id="refresh"
        class="refresh"
        dark
        @click="loadData"
        v-if="allowRefresh"
      >
        <v-icon class="v-btn__pre-icon" small>mdi-refresh</v-icon>&nbsp; Refresh
      </v-btn>

      <v-btn
        v-if="allowFilters"
        :class="[$vuetify.breakpoint.width > 800 ? 'my-2' : '']"
        icon
        style="margin-left: 10px"
      >
        <v-icon @click="$emit('filter')">mdi-filter</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table
      :loading="loading"
      :items="items"
      :headers="headersValue"
      :search="search"
      height="calc(100vh - 270px)"
      mobile-breakpoint="800"
      fixed-header
    >
      <template v-slot:item="{ item }">
        <tr style="height: 80px" v-if="$vuetify.breakpoint.width > 800">
          <td
            v-for="(elem, key) of headers"
            :key="key"
            :class="`text-${elem.align === 'right' ? 'end' : 'start'}`"
            :style="`max-width: calc(100% / ${headersValue.length} )`"
          >
            <slot :name="elem.value" :item="item">{{ item[elem.value] }}</slot>
          </td>
          <td
            v-if="
              viewHandler ||
              editHandler ||
              deleteHandler ||
              disableHandler ||
              voucherHandler ||
              accountHandler ||
              customButton ||
              stampCardsHandler ||
              voucherTermsHandler
            "
            :style="`max-width: calc(100% / ${headersValue.length})`"
            class="py-2"
          >
            <div
              class="d-flex justify-end align-center flex-wrap"
              style="gap: 8px"
            >
              <slot name="extra-actions" :item="item" />

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="voucherTermsHandler"
                    @click="voucherTermsHandler(item)"
                    style="color: #30bfcb; margin: 0px 5px; cursor: pointer"
                    small
                    v-on="on"
                    >mdi-note
                  </v-icon>
                </template>
                <span>Terms & Conditions</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="stampCardsHandler"
                    @click="stampCardsHandler(item)"
                    style="margin: 0px 5px; cursor: pointer"
                    small
                    v-on="on"
                    >mdi-ticket-percent
                  </v-icon>
                </template>
                <span>Stamp Cards</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <i
                    v-if="accountHandler"
                    @click="accountHandler(item)"
                    class="icon pi pi-euro"
                    style="color: #ff7915; margin: 0px 5px; cursor: pointer"
                    v-on="on"
                  />
                </template>
                <span>Account Details</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="voucherHandler"
                    @click="voucherHandler(item)"
                    color="#2596be"
                    style="margin: 0px 5px; cursor: pointer"
                    small
                    v-on="on"
                    >mdi-ticket
                  </v-icon>
                </template>
                <span>Vouchers</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <i
                    v-if="viewHandler"
                    @click="viewHandler(item)"
                    class="icon pi pi-eye"
                    style="color: #42b983; margin: 0px 5px; cursor: pointer"
                    v-on="on"
                  />
                </template>
                <span>Details</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <i
                    v-if="editHandler"
                    @click="editHandler(item)"
                    class="icon pi pi-pencil"
                    style="color: orange; margin: 0px 5px; cursor: pointer"
                    v-on="on"
                  />
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <i
                    v-if="deleteHandler"
                    @click="showDeleteConfirmation(item)"
                    class="icon pi pi-trash"
                    style="color: red; margin: 0px 5px; cursor: pointer"
                    v-on="on"
                  />
                </template>
                <span>Delete</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="disableHandler"
                    @click="disableHandler(item)"
                    color="red"
                    small
                    class="margin: 0px 5px"
                    v-on="on"
                    >mdi-block-helper
                  </v-icon>
                </template>
                <span>Disable Voucher</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
        <tr v-else class="v-data-table__mobile-table-row" style="height: 100%">
          <td
            v-for="(elem, key) of headers"
            :key="key"
            :class="`v-data-table__mobile-row text-${
              elem.align === 'right' ? 'end' : 'start'
            }`"
          >
            <div class="v-data-table__mobile-row__header">{{ elem.text }}</div>
            <div class="v-data-table__mobile-row__cell">
              <slot :name="elem.value" :item="item"
                >{{ item[elem.value] }}
              </slot>
            </div>
          </td>
          <td
            v-if="
              viewHandler ||
              editHandler ||
              deleteHandler ||
              disableHandler ||
              voucherHandler ||
              accountHandler ||
              stampCardsHandler
            "
            class="v-data-table__mobile-row text-end"
            style="text-align: right"
          >
            <div class="v-data-table__mobile-row__header">Action</div>
            <div class="v-data-table__mobile-row__cell d-flex flex-column">
              <slot name="extra-actions" :item="item" />
              <v-btn
                v-if="voucherTermsHandler"
                class="my-1"
                dark
                small
                @click="voucherTermsHandler(item)"
                color="#30bfcb"
              >
                Terms & Conditions
              </v-btn>
              <v-btn
                class="my-1"
                dark
                v-if="stampCardsHandler"
                small
                @click="stampCardsHandler(item)"
                color="primary"
              >
                Stamp Cards
              </v-btn>

              <v-btn
                class="my-1"
                dark
                v-if="accountHandler"
                small
                @click="accountHandler(item)"
                color="primary"
              >
                Account Details
              </v-btn>

              <v-btn
                class="my-1"
                dark
                v-if="voucherHandler"
                small
                @click="voucherHandler(item)"
                color="primary"
              >
                Vouchers
              </v-btn>

              <v-btn
                class="my-1"
                dark
                v-if="viewHandler"
                small
                @click="viewHandler(item)"
                color="primary"
              >
                View
              </v-btn>
              <v-btn
                dark
                class="my-1"
                v-if="editHandler"
                small
                color="green"
                @click="editHandler(item)"
              >
                Edit
              </v-btn>
              <v-btn
                dark
                class="my-1"
                v-if="deleteHandler"
                small
                color="red"
                @click="showDeleteConfirmation(item)"
              >
                Delete
              </v-btn>
              <v-btn
                class="my-1"
                dark
                v-if="disableHandler"
                small
                @click="disableHandler(item)"
                color="red"
              >
                Disable
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <error-dialog v-if="!hideDialog" v-model="error" :error="errorValue" />

    <v-dialog v-model="showConfirmationModal" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation !</v-card-title>
        <v-card-text>
          Are you sure you want to delete this item? This Process can not be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue " text @click="cancelDelete"> Cancel </v-btn>
          <v-btn
            color="
            primary
          "
            text
            @click="onDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ErrorDialog from "./ErrorDialog";

export default {
  name: "DataTable",
  components: { ErrorDialog },
  props: {
    allowRefresh: {
      type: Boolean,
      default: true,
    },
    allowSearch: {
      type: Boolean,
      default: true,
    },
    allowAdd: {
      type: Boolean,
      default: false,
    },
    allowDeposit: {
      type: Boolean,
      default: false,
    },
    allowInvoice: {
      type: Boolean,
      default: false,
    },
    addBenefits: {
      type: Boolean,
      default: false,
    },

    allowFilters: {
      type: Boolean,
      default: false,
    },

    allowBack: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },

    loader: {
      type: Function,
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },

    editHandler: {
      type: Function,
      default: null,
    },

    viewHandler: {
      type: Function,
      default: null,
    },

    customButton: {
      type: Boolean,
      default: false,
    },

    deleteHandler: {
      type: Function,
      default: null,
    },

    voucherHandler: {
      type: Function,
      default: null,
    },

    disableHandler: {
      type: Function,
      default: null,
    },

    accountHandler: {
      type: Function,
      default: null,
    },

    stampCardsHandler: {
      type: Function,
      default: null,
    },
    voucherTermsHandler: {
      type: Function,
      default: null,
    },

    hasElevation: {
      type: Boolean,
      default: true,
    },
    hideDialog: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["add-new"],

  mounted() {
    this.headersValue = [...this.headers];
    if (
      this.editHandler ||
      this.deleteHandler ||
      this.viewHandler ||
      this.disableHandler ||
      this.voucherHandler ||
      this.accountHandler ||
      this.stampCardsHandler ||
      this.customButton
    ) {
      this.headersValue.push({
        text: "Actions",
        align: "right",
        search: false,
        sortable: false,
      });
    }

    this.loadData();
  },

  data: () => ({
    search: "",
    items: [],
    error: false,
    loading: false,
    errorValue: {},
    headersValue: [],
    showConfirmationModal: false,
    itemToDelete: null,
  }),

  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.loader();

        // sort by date
        if (this.items) {
          this.items.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.errorValue = {
          title: "Error while loading data",
          description:
            e?.response?.data?.error ??
            "Some error occurred, Please try again later",
        };
        this.error = true;
      }
    },

    showDeleteConfirmation(item) {
      this.itemToDelete = item;
      this.showConfirmationModal = true;
    },

    async onDelete() {
      try {
        this.showConfirmationModal = false;
        this.loading = true;
        await this.deleteHandler(this.itemToDelete);
        this.loading = false;
        const index = this.items.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.items.splice(index, 1);
        }
      } catch (e) {
        console.log(e);
      }
    },

    cancelDelete() {
      this.itemToDelete = null;
      this.showConfirmationModal = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.data-table

  &__header
    font-size: 25px
    font-family: google-sans, sans-serif
</style>
