import StampCardsView from "./StampCardsView";

export const stampCardsRoutes = [
  {
    name: "Stamp Cards",
    path: "/stamp-cards-all",
    component: StampCardsView,
  },
];

export const stampCardsRouter = stampCardsRoutes.filter(function (x) {
  return x !== null;
});
