import axios from "axios";

export class RedeemVouchersService {
  async fetchAll() {
    return (await axios.get("/voucher/all-redeemed")).data;
  }

  async fetchAllVouchers(id) {
    return (await axios.get("/voucher/all-redeemed?restaurantId=" + id)).data;
  }

  async fetchOne(id) {
    return (await axios.get("/voucher/single?voucherId=" + id)).data;
  }

  async fetchMerchantVouchers(id) {
    return (await axios.get("voucher/merchant/redeemed-vouchers/" + id)).data;
  }
}