import View from "./View";
import Form from "./Form.vue";
// import { getUser } from "../../utils/local";

export const merchantMessagesRoutes = [
  // getUser()?.role === "ADMIN"
  //   ?
  {
    name: "MerchantMessages",
    path: "/merchant-messages",
    component: View,
  },
  // : {
  //     path: "/merchant-messages",
  //     redirect: "/no-permission",
  //   },
  // getUser()?.role === "ADMIN"
  //   ?
  {
    name: "NewMerchantMessage",
    path: "/merchant-message",
    component: Form,
  },
  // : {
  //     path: "/merchant-message",
  //     redirect: "/no-permission",
  //   },
];

export const merchantMessagesRouter = merchantMessagesRoutes.filter(function (
  x
) {
  return x !== null;
});
