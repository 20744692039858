<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row class="d-flex align-center mb-8" no-gutters>
          <v-btn @click="$router.go(-1)" elevation="0">
            <v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </v-row>

        <v-row class="pa-8" no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <qrcode-vue
              :value="String(merchant.stampCardUniqueCode)"
              size="300"
              level="H"
            />
          </v-col>

          <v-col cols="12" class="d-flex justify-center mt-10">
            <v-btn color="primary" class="white--text" @click="qrPrint">
              Print
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog
      v-model="qrDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar elevation="0"> </v-toolbar>

        <v-card-text>
          <v-row class="d-flex justify-center" style="margin-top: 10%">
            <qrcode-vue
              :value="String(merchant.stampCardUniqueCode)"
              size="300"
              level="H"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { MerchantsService } from "../../services/merchant-service";

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      dataLoading: true,
      merchants_service: new MerchantsService(),

      qrDialog: false,

      merchant: {},
      merchants: [],
      user: {},
    };
  },
  methods: {
    qrPrint() {
      this.qrDialog = true;

      setTimeout(() => {
        window.print();
      }, 500);

      setTimeout(() => {
        this.qrDialog = false;
      }, 1000);
    },
    async loadMerchant() {
      const merchants = await this.merchants_service.fetchAll();
      this.merchants = merchants;
      this.merchants = this.merchants.filter(
        (merchant) => merchant.userId && merchant.userId._id === this.user._id
      );
      this.merchant = this.merchants[0];

      this.dataLoading = false;
    },
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    await this.loadMerchant();
  },
};
</script>
