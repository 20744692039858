import axios from "axios";

export class MerchantMessagesService {
  async fetchAll() {
    return (await axios.get("/merchant-message")).data;
  }

  async fetchAllByMerchant(id) {
    return (await axios.get("/merchant-message/get-merchant-messages/" + id))
      .data;
  }

  async fetchOne(id) {
    return (await axios.get("/merchant-message/" + id)).data;
  }

  async create(data) {
    return (await axios.post("/merchant-message", data)).data;
  }

  async update(id, data) {
    return (await axios.patch("/merchant-message/" + id, data)).data;
  }

  async delete(data) {
    return (await axios.delete("/merchant-message/" + data._id)).data;
  }
}
