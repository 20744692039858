<template>
  <div>
    <div>
      <data-table
        :loader="loadData"
        :headers="headers"
        :allow-add="false"
        @done="$router.back()"
        :delete-handler="null"
        :edit-handler="null"
        :view-handler="null"
        :custom-button="true"
      >
        <template #firstname="{ item }">
          {{ item.firstname }}
        </template>
        <template #extra-actions="{item}">
          <v-btn outlined color="primary" class="v-btn v-btn--has-bg v-size--default mr-2 hover"
                 @click="view(item)">View Posts
          </v-btn>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import { getUserScopes } from "../../utils/local";
import { ReporteduserService } from "@/services/reporteduser-service";

export default {
  components: { DataTable },

  data: () => ({
    items: [],
    report_service: new ReporteduserService(),
    userScopes: getUserScopes(),
    headers: [
      {
        text: "First Name",
        value: "firstname",
        sortable: true
      },
      {
        text: "Surname",
        value: "surname",
        sortable: true
      },
      {
        text: "Email",
        value: "email",
        sortable: true
      },
      {
        text: "Posts",
        value: "postCount",
        sortable: true
      }
    ]
  }),

  methods: {
    view(item) {
      this.$router.push(`/post-details?id=${item.user_id}`);
    },

    async deleteUser(item) {
      await this.users_service.delete(item);
    },

    async loadData() {
      return await this.report_service.fetchAll();
    }
  }
};
</script>
<style scoped>
.hover:hover {
  background-color: #ff6e01;
  color: white !important;
  border: 1px solid #ff6e01;
  transition: 0.5s ease;
}
</style>