<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row
          class="d-flex align-center"
          style="margin-bottom: 12px"
          no-gutters
        >
          <v-btn @click="$router.go(-1)" elevation="0">
            <v-icon class="v-btn__pre-icon">mdi-arrow-left</v-icon></v-btn
          >
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" class="mb-8">
            <v-img height="250px" contain :src="voucherImg">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                  style="background: #e5e5e5"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Restaurant Name"
              v-model="merchant.restaurantName"
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Voucher Title"
              v-model="voucher.voucherObject[0].title"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Voucher Type"
              v-model="voucher.voucherObject[0].voucherType"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-textarea
              disabled
              outlined
              class="text-color"
              dense
              label="Description"
              v-model="voucher.voucherObject[0].description"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Discount"
              v-model="voucher.voucherObject[0].discount"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Voucher Preference"
              v-model="voucher.voucherObject[0].voucherPreference"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Estimated Cost"
              v-model="voucher.voucherObject[0].estimatedCost"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Estimated Savings"
              v-model="voucher.voucherObject[0].estimatedSavings"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col
            cols="12"
            md="5"
            v-if="
              voucher.voucherObject[0].voucherDisableDates &&
              voucher.voucherObject[0].voucherDisableDates.length > 0
            "
          >
            <v-card-text>
              <b>Disabled dates : </b>
              <ul>
                <li
                  v-for="date in voucher.voucherObject[0].voucherDisableDates"
                  :key="date"
                >
                  {{ formatDate(date) }}
                </li>
              </ul>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="5"></v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { RedeemVouchersService } from "../../services/redeem-vouchers-service";
import { MerchantsService } from "../../services/merchant-service";
import { getFullPath } from "../../utils/local";
import merchantsForm from "../merchants/MerchantsForm.vue";

export default {
  computed: {
    merchantsForm() {
      return merchantsForm;
    },
  },
  data() {
    return {
      dataLoading: true,
      voucher: {},
      voucherImg: "",
      redeem_vouchers_service: new RedeemVouchersService(),
      merchants_service: new MerchantsService(),

      headers: [
        {
          text: "Mind Feels",
          value: "my_mind_feels",
          sortable: true,
        },
        {
          text: "Body Feels",
          value: "my_body_feels",
          sortable: true,
        },
        {
          text: "Spirit Feels",
          value: "my_spirit_feels",
          sortable: true,
        },
        {
          text: "Date",
          value: "date",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("D MMM YYYY");
    },

    async loadVoucher() {
      try {
        this.dataLoading = true;
        this.voucher = await this.redeem_vouchers_service.fetchOne(
          this.$route.query.id
        );
        await this.loadVoucherImg();
        await this.loadMerchant();
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    },

    async loadVoucherImg() {
      this.voucherImg = getFullPath(this.voucher.voucherObject[0].voucherImage);
      console.log(this.voucherImg);
    },

    async loadMerchant() {
      try {
        this.dataLoading = true;
        this.merchant = await this.merchants_service.fetchOne(
          this.voucher.restaurantId
        );
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    },
  },
  async mounted() {
    await this.loadVoucher();
  },
};
</script>
