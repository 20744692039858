<template>
  <div class="my-12">
    <h3 class="text-center mb-4">Reported Content</h3>
    <v-card :loading="loading" class="mx-auto" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-carousel
        height="300px"
        :hide-delimiters="true"
        :show-arrows="item.postId?.media.length > 1"
        v-if="item.postId?.media.length > 0"
      >
        <v-carousel-item v-for="img in item.postId?.media" :key="img">
          <img
            v-if="isVideo(img)"
            :src="'http://192.168.1.30:3000/uploads/' + img"
            class="full-img"
          />
          <a
            class="a-padding"
            :href="'http://192.168.1.30:3000/uploads/' + img"
            v-else
            >http://192.168.1.30:3000/uploads/{{ img }}</a
          >
        </v-carousel-item>
      </v-carousel>

      <v-card-text>
        <div>{{ item?.postId?.caption }}</div>
        <div class="mt-4">
          <small class="mr-4">Likes : {{ item?.postId?.likesCount }}</small>
          <small>Comments : {{ item?.postId?.commentCount }}</small>
        </div>
      </v-card-text>

      <v-divider class="mb-4" />
      <v-row align="center" class="mx-0">
        <v-card-title class="text-subtitle-1">Reported By</v-card-title>
      </v-row>
      <v-list>
        <v-list-item :key="reporter.email" v-for="reporter in reporters">
          <v-list-item-avatar>
            <img
              :src="'http://192.168.1.30:3000/uploads/' + reporter.profileImage"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              v-html="`${reporter.username} ${reporter.surname}`"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-html="reporter.email"
            ></v-list-item-subtitle>
            <v-list-item-subtitle class="mt-3"
              >Reported As:
              <b>{{ reporter.reportReason }}</b></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { ReporteduserService } from "@/services/reporteduser-service";
import { getUserScopes } from "@/utils/local";

export default {
  name: "ReportedPost",
  data: () => ({
    videoExtensions: [
      "mp4",
      "avi",
      "mkv",
      "wmv",
      "mov",
      "flv",
      "webm",
      "3gp",
      "3g2",
      "mpeg",
      "vob",
      "ts",
      "ogv",
      "xvid",
    ],
    item: {},
    reporters: [],
    loading: false,
    report_services: new ReporteduserService(),
    userScopes: getUserScopes(),
  }),
  methods: {
    isVideo(image) {
      const extension = image.split(".").pop(); // Get the file extension
      return !this.videoExtensions.includes(extension.toLowerCase());
    },
    async loadData() {
      let { post, reporters } = await this.report_services.fetchOne(
        this.$route.query.id,
        this.$route.query.postId
      );
      this.item = post;
      this.reporters = reporters;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.a-padding {
  display: block;
  padding: 20px;
}

.full-img {
  width: 100%;
  height: 100%;
}
</style>
