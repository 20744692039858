<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row class="d-flex align-center mb-8" no-gutters>
          <v-btn @click="$router.go(-1)" elevation="0">
            <v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Title"
              v-model="stampCard.title"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Stamp card Type"
              v-model="stampCard.type"
            />
          </v-col>
        </v-row>

        <!-- description -->
        <v-row class="pl-8 pr-8" no-gutters>
          <v-col cols="12">
            <v-textarea
              disabled
              outlined
              class="text-color"
              dense
              label="Description"
              v-model="stampCard.description"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Total Points"
              v-model="stampCard.totalPoints"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Reward"
              v-model="stampCard.reward"
            />
          </v-col>
        </v-row>

        <v-row class="pl-8" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Time Duration In Days"
              v-model="stampCard.timeDurationInDays"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="6">
            <v-card-text>
              <b>Redeemed At : </b>{{ formatDate(stampCard.updatedAt) }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="5">
            <!-- <v-card-text>
              <b>Updated At : </b>{{ formatDate(stampCard.updatedAt) }}
            </v-card-text> -->
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { MerchantsService } from "../../services/merchant-service";
import { getFullPath } from "../../utils/local";

export default {
  data() {
    return {
      dataLoading: true,
      stampCard: {},
      merchants_service: new MerchantsService(),
    };
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format("D MMM YYYY");
    },

    async loadVoucher() {
      try {
        this.dataLoading = true;

        this.stampCard = await this.merchants_service.fetchOneStampCard(
          this.$route.query.stampCardId
        );
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    },
  },
  async mounted() {
    await this.loadVoucher();
  },
};
</script>
