import { getUser } from "../../utils/local";
import ReportedUserView from "@/views/reportedUsers/ReportedUserView.vue";
import ReportPostView from "@/views/reportedUsers/ReportPostView.vue";
import ReportedPost from "@/views/reportedUsers/ReportedPost.vue";

export const reportedUserRoutes = [
  getUser()?.role === "ADMIN"
    ? {
      name: "ReportedUsers",
      path: "/reported-users",
      title: "Reported Users",
      component: ReportedUserView
    }
    : {
      path: "/users",
      redirect: "/no-permission"
    },

  getUser()?.role === "ADMIN"
    ? {
      name: "ReportedPosts",
      path: "/post-details",
      title: "Reported Posts",
      component: ReportPostView
    }
    : {
      path: "/users",
      redirect: "/no-permission"
    },

  getUser()?.role === "ADMIN"
    ? {
      name: "ReportedPosts",
      path: "/user-post",
      title: "Reported Posts",
      component: ReportedPost
    }
    : {
      path: "/users",
      redirect: "/no-permission"
    },
];

export const reportedUserRouter = reportedUserRoutes.filter(function(x) {
  return x !== null;
});
