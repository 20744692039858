<template>
  <div class="wrapper">
    <img src="../assets/logo-loader.jpg" alt="logo" class="logo" />
  </div>
</template>

<script>
import { getUser } from "../utils/local";
export default {
  name: "Home",
  data: () => ({
    user: getUser(),
  }),
  mounted() {
    setTimeout(() => {
      if (this.user?.role === "ADMIN") {
        // Redirect to users page if user is admin
        this.$router.push("/users");
      } else {
        // Redirect to merchant details page if user is merchant
        this.$router.push("/merchant-details");
      }
    }, 1000);
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.wrapper img {
  width: 100px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
