<template>
  <div>
    <v-card class="mx-auto pa-6" max-width="800">
      <v-overlay
        v-if="dataLoading"
        :absolute="dataLoading"
        color="primary"
        opacity="0.55"
      >
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
        <span style="color: #fff; margin-left: 10px"> Please Wait... </span>
      </v-overlay>

      <div v-else>
        <v-row
          class="pa-0 d-flex span-2"
          :class="user?.role === 'ADMIN' ? 'justify-start' : 'justify-end'"
          style="margin-bottom: 12px"
          no-gutters
        >
          <v-btn
            v-if="user?.role === 'ADMIN'"
            @click="$router.go(-1)"
            elevation="0"
          >
            <v-icon class="v-btn__pre-icon">mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            v-else
            dark
            color="primary"
            elevation="0"
            @click="$router.push('/merchant?id=' + merchant._id)"
            >Edit
          </v-btn>
        </v-row>

        <v-row
          class="pa-0 mt-8"
          style="justify-content: space-around"
          no-gutters
        >
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Merchant Name"
              v-model="merchant.restaurantName"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Contact Number"
              v-model="merchant.phoneNumber"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-textarea
              disabled
              outlined
              class="text-color"
              dense
              label="Description"
              v-model="merchant.description"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Location"
              v-model="merchant.locationName"
            />
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Voucher Count"
              v-model="merchant.totalVoucherCount"
            />
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Unique Code"
              v-model="merchant.uniqueCode"
              :type="uniCodeVisible ? 'text' : 'password'"
            >
              <template v-slot:append-outer>
                <v-btn
                  class="mt-n1 ml-n2"
                  icon
                  @click="uniCodeVisible = !uniCodeVisible"
                >
                  <v-icon
                    >{{ uniCodeVisible ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-text-field
              disabled
              outlined
              class="text-color"
              dense
              label="Sponsored"
              v-model="merchant.isSponsored"
            />
          </v-col>
          <v-col cols="12" md="5"></v-col>
        </v-row>

        <v-row class="pa-0 my-5" no-gutters>
          <v-card-title>Merchant Media:</v-card-title>

          <v-carousel
            v-if="media.length > 0"
            height="300"
            class="span-2 mb-4"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              contain
              v-for="(image, index) in media"
              :key="index"
              :src="image"
              style="object-fit: cover"
            ></v-carousel-item>
          </v-carousel>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-card-text class="p10">
              <b>Tags : </b>
              <ul class="no-bullet">
                <v-chip
                  v-for="tag in merchant.tags"
                  :key="tag"
                  small
                  class="ma-2"
                  >{{ tag }}
                </v-chip>
              </ul>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="5">
            <v-card-text class="p10">
              <b>Dietary Restrictions : </b>
              <ul class="no-bullet">
                <v-chip
                  v-for="dietary in merchant.dietaryRestrictions"
                  :key="dietary"
                  small
                  class="ma-2"
                  >{{ dietary }}
                </v-chip>
              </ul>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row class="pa-0" style="justify-content: space-around" no-gutters>
          <v-col cols="12" md="5">
            <v-card-text class="p10">
              <b>Culinary Options : </b>
              <ul class="no-bullet">
                <v-chip
                  v-for="culinary in merchant.culinaryOptions"
                  :key="culinary"
                  small
                  class="ma-2"
                  >{{ culinary }}
                </v-chip>
              </ul>
            </v-card-text>
          </v-col>

          <v-col cols="12" md="5"></v-col>
        </v-row>

        <v-row class="pa-8" no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <qrcode-vue :value="String(merchant.uniqueCode)" size="300" />
          </v-col>
        </v-row>

        <v-row class="pa-8" no-gutters>
          <v-col cols="12" md="6">
            <v-card-text class="p10">
              <b>Created At : </b>{{ formatDate(merchant.createdAt) }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="6">
            <v-card-text class="p10">
              <b>Updated At : </b>{{ formatDate(merchant.updatedAt) }}
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { MerchantsService } from "../../services/merchant-service";
import { getFullPath, getUser } from "../../utils/local";
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      dataLoading: true,
      uniCodeVisible: false,
      user: getUser(),
      merchant: {},
      merchants_service: new MerchantsService(),
      media: [],
      menu: "",

      merchants: [],
    };
  },
  components: {
    QrcodeVue,
  },
  methods: {
    getFullPath,
    formatDate(date) {
      return dayjs(date).format("D MMM YYYY - hh:mm A");
    },

    async loadMerchant() {
      try {
        this.dataLoading = true;
        if (this.$route.query.id) {
          this.merchant = await this.merchants_service.fetchOne(
            this.$route.query.id
          );

          this.merchant.media.forEach((media) => {
            this.media.push(this.getFullPath(media));
          });
        } else {
          let merchants = await this.merchants_service.fetchAll();
          merchants = merchants.filter(
            (merchant) =>
              merchant.userId && merchant.userId._id === this.user._id
          );
          this.merchant = merchants[0];
          this.merchant?.media.forEach((media) => {
            this.media.push(this.getFullPath(media));
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.dataLoading = false;
    },
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    await this.loadMerchant();
  },
};
</script>
