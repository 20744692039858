<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Reported Posts"
      :allow-back="true"
      :allow-add="false"
      @done="$router.back()"
      :delete-handler="null"
      :edit-handler="null"
      :view-handler="userScopes.includes('users:view') ? view : null"
    >
      <template #firstname="{ item }">
        {{ item.firstname }}
      </template>

      <template #caption="{ item }">
        {{ item.caption || "No Caption" }}
      </template>

      <template #primary-action>
        <v-dialog v-model="showDatePicker" max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              Block
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">Select Date</v-card-title>
            <v-date-picker full-width v-model="pickerDate"></v-date-picker>

            <v-card-actions
              v-if="oldPickerDate < new Date().toISOString().substr(0, 10)"
            >
              <v-card-text>User is not blocked. </v-card-text>

              <v-btn
                small
                color="red"
                outlined
                @click="
                  showDatePicker = false;
                  blockPermanently();
                "
              >
                Block Permanently
              </v-btn>
            </v-card-actions>

            <v-card-actions v-else>
              <v-card-text v-if="oldPickerDate !== '2070-01-01'"
                >User is blocked until:
                <strong>{{
                  dayjs(oldPickerDate).subtract(1, "day").format("YYYY-MM-DD")
                }}</strong>
              </v-card-text>
              <v-card-text v-else>User is permanently blocked.</v-card-text>

              <v-btn
                small
                color="red"
                outlined
                @click="
                  showDatePicker = false;
                  unblock();
                "
              >
                Unblock
              </v-btn>
            </v-card-actions>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDatePicker = false">
                Cancel
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="
                  showDatePicker = false;
                  handleChange(pickerDate);
                "
              >
                Block
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </data-table>
    <loading-dialog v-model="loading" :message="message" />
  </div>
</template>

<script>
import { required } from "@/utils/validators";
import { ReporteduserService } from "@/services/reporteduser-service";
import DataTable from "@/components/DataTable.vue";
import LoadingDialog from "../../components/LoadingDialog";
import { getUserScopes } from "@/utils/local";
import dayjs from "dayjs";

export default {
  name: "ReportPostView",
  components: { DataTable, LoadingDialog },

  data: () => ({
    message: "",
    loading: false,
    showDatePicker: false,
    pickerDate: null,
    oldPickerDate: null,
    report_services: new ReporteduserService(),
    userScopes: getUserScopes(),
    headers: [
      {
        text: "Caption",
        value: "caption",
        sortable: true,
      },
      {
        text: "Likes Count",
        value: "likesCount",
        sortable: true,
      },
      {
        text: "Comment Count",
        value: "commentCount",
        sortable: true,
      },
    ],
    reportBy: {},
    mainId: "",
    fcmToken: "",
  }),

  methods: {
    required,
    dayjs,
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },

    async handleChange(newDate) {
      this.message = "Banning the user";
      let banDate = dayjs(newDate).add(1, "day").format("YYYY-MM-DD");

      this.loading = true;
      this.showDatePicker = false;
      let resp = await this.report_services.update({
        _id: this.$route.query.id,
        bannedUntil: banDate,
      });

      await this.$axios.post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            title: "Account Blocked",
            body: "Account permanently blocked due to repeated violations.",
            content_available: true,
          },
          priority: "high",
          to: this.fcmToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "key=AAAAX9IeJbA:APA91bH37zaDbLqA9prgmK_ytVyQN102fj0u0MRI9uJn4LPQHjIf4rlSXB6KeIt7fYCYQOMvtbw6nvIDncp83L8ynN11JsbsYjSxeZ5kciGagWPWm3Te0j0bopMilw0XFZmB0E2lvsrI",
          },
        }
      );

      await this.loadData();
      this.loading = false;
      return resp;
    },
    async blockPermanently() {
      this.message = "Banning the user";
      this.loading = true;
      let resp = await this.report_services.update({
        _id: this.$route.query.id,
        bannedUntil: "2070-01-01",
      });
      this.loading = false;

      await this.loadData();
      return resp;
    },

    async unblock() {
      this.message = "Unblocking the user";
      this.loading = true;
      this.picker = null;
      let resp = await this.report_services.update({
        _id: this.$route.query.id,
        bannedUntil: "1970-01-01",
      });
      this.loading = false;

      await this.loadData();
      return resp;
    },

    view(item) {
      this.$router.push(`/user-post?id=${this.mainId}&postId=${item._id}`);
    },

    async loadData() {
      let resp = await this.report_services.getPosts(this.$route.query.id);
      this.fcmToken = resp[0].userId.fcmToken;
      this.mainId = resp[0].mainId;
      this.reportBy = resp[0].reportBy;

      this.oldPickerDate = this.formatDate(resp[0].userId.bannedUntil);
      if (
        this.formatDate(resp[0].userId.bannedUntil) === "1970-01-01" ||
        this.formatDate(resp[0].userId.bannedUntil) === "2070-01-01"
      ) {
        this.pickerDate = new Date().toISOString().substr(0, 10);
      } else {
        this.pickerDate = dayjs(resp[0].userId.bannedUntil)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        console.log(this.pickerDate);
      }

      resp = resp.map((item) => {
        return item.postId;
      });
      resp = resp.filter((item) => item !== null);

      return resp;
    },
  },
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

#media > img:not(:first-child) {
  margin-left: -50px;
}

.image-size {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.margin {
  margin-bottom: -54px;
  z-index: 3;
  width: fit-content;
}

.hover:hover {
  background-color: #ff6e01;
  color: white !important;
  border: 1px solid #ff6e01;
  transition: 0.5s ease;
}
</style>
