import VouchersView from "./VouchersView";
import VouchersShow from "./VouchersShow";

export const redeemVouchersRoutes = [
  {
    name: "Redeem Vouchers",
    path: "/redeem-vouchers",
    component: VouchersView,
  },

  {
    name: "Redeem VoucherDetails",
    path: "/redeem-voucher-details",
    component: VouchersShow,
  },
];

export const redeemVouchersRouter = redeemVouchersRoutes.filter(function (x) {
  return x !== null;
});
