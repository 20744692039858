import Vue from "vue";
import VueRouter from "vue-router";
import SignInView from "./views/auth/SignIn";
import NotFound from "./views/404.vue";
import Dashboard from "./components/Dashboard";
import { usersRouter } from "./views/users/router";
import { noPermissionRouter } from "./views/permission/router";

import { merchantsRouter } from "./views/merchants/router";
import { redeemVouchersRouter } from "./views/redeemVouchers/router";
import { quotesRouter } from "./views/quotes/router";
import { accountsHistoyRouter } from "./views/accountsHistory/router";
import { customerSupportsRouter } from "./views/customerSupport/router";
import { CustumNotificationRouter } from "./views/custum-notification/router";
import { stampCardsRouter } from "./views/stampCards/router";
import Home from "./views/Home.vue";
import { reportedUserRouter } from "@/views/reportedUsers/router";
import { passwordRoutes } from "@/views/updatePassword/router";
import { merchantMessagesRouter } from "@/views/merchantMessages/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Dashboard,
    children: [
      {
        name: "Home",
        path: "/",
        component: Home,
      },
      ...usersRouter,
      ...noPermissionRouter,
      ...merchantsRouter,
      ...redeemVouchersRouter,
      ...quotesRouter,
      ...accountsHistoyRouter,
      ...customerSupportsRouter,
      ...CustumNotificationRouter,
      // ...merchantRedeemVouchersRouter,
      // ...postsRouter,
      ...stampCardsRouter,
      ...reportedUserRouter,
      ...passwordRoutes,
      ...merchantMessagesRouter,
    ],
  },
  {
    name: "SignIn",
    path: "/auth/sign-in",
    component: SignInView,
  },
  {
    name: "NotFound",
    path: "**",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Check if user is logged in or not before each route change and redirect accordingly
router.beforeEach((to, __, next) => {
  if (!localStorage.getItem("auth_token")) {
    if (to.path !== "/auth/sign-in") {
      next("/auth/sign-in");
    }
  }

  next();
});

export default router;
