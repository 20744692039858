<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      :allow-add="true"
      @done="$router.back()"
      @add-new="addNew"
      :delete-handler="deleteStampCard"
      :edit-handler="edit"
      :view-handler="view"
      :allow-back="user?.role === 'ADMIN' ? true : false"
      :key="dataTableKey"
    >
    </data-table>

    <loading-dialog v-model="dataLoading" message="Please wait..." />
  </div>
</template>

<script>
import { MerchantsService } from "../../services/merchant-service";
import DataTable from "../../components/DataTable";
import { required } from "../../utils/validators";
import LoadingDialog from "../../components/LoadingDialog";
import { getUser } from "../../utils/local";

export default {
  components: { DataTable, LoadingDialog },

  mounted() {
    this.loadData;
  },

  data: () => ({
    user: getUser(),
    items: [],
    merchants_service: new MerchantsService(),
    dataLoading: false,
    dataTableKey: 0,

    headers: [
      {
        text: "Name",
        value: "title",
        sortable: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: true,
      },
      {
        text: "Reward",
        value: "reward",
        sortable: true,
      },
      {
        text: "Total Points",
        value: "totalPoints",
        sortable: true,
      },
      {
        text: "Time Duration In Days",
        value: "timeDurationInDays",
        sortable: true,
      },
    ],
  }),

  methods: {
    required,

    addNew() {
      this.$router.push(`/stamp-card/?id=${this.$route.query.restaurantId}`);
    },

    edit(item) {
      this.$router.push(
        `/stamp-card?restaurantId=${this.$route.query.restaurantId}&stampCardId=${item._id}`
      );
    },
    view(item) {
      this.$router.push(
        `/stamp-card-details?restaurantId=${this.$route.query.restaurantId}&stampCardId=${item._id}`
      );
    },

    async deleteStampCard(item) {
      await this.merchants_service.deleteStampCard(item._id);
    },

    async loadData() {
      if (this.$route.query.restaurantId) {
        const id = this.$route.query.restaurantId;
        const data =
          await this.merchants_service.fetchAllStampCardsByRestaurant(id);
        return data;
      }
    },
  },
};
</script>
