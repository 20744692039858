import MerchantsView from "./MerchantsView";
import VouchersView from "./VouchersView";
import AccountsView from "./AccountsView";
import StampCardsView from "./StampCardsView.vue";

import MerchantShow from "./MerchantsShow";
import VoucherShow from "./VouchersShow";
import StampCardShow from "./StampCardsShow.vue";


import MerchantQrCodeShow from "./MerchantQrCodeShow.vue";

import MerchantForm from "./MerchantsForm.vue";
import VoucherForm from "./VouchersForm.vue";
import StampCardForm from "./StampCardsForm.vue";
import VoucherTermsConditionsForm from "@/views/merchants/VoucherTermsConditionsForm.vue";

import {getUser} from "@/utils/local";

export const merchantsRoutes = [
    //-------- Voucher Routes --------------//
    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "Vouchers",
            path: "/vouchers",
            component: VouchersView,
        }
        : {
            path: "/vouchers",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "AddVoucher",
            path: "/voucher",
            component: VoucherForm,
        }
        : {
            path: "/voucher",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "VoucherDetails",
            path: "/voucher-details",
            component: VoucherShow,
        }
        : {
            path: "/voucher-details",
            redirect: "/no-permission",
        },

    //--------- Merchant Routes ------------//
    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "Merchants",
            path: "/merchants",
            component: MerchantsView,
        }
        : {
            path: "/merchants",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "NewMerchant",
            path: "/merchant",
            component: MerchantForm,
        }
        : {
            path: "/merchant",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "VoucherTermsAndCondition",
            path: "/voucher-terms-conditions",
            component: VoucherTermsConditionsForm,
        }
        : {
            path: "/voucher-terms-conditions",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "MerchantsDetails",
            path: "/merchant-details",
            component: MerchantShow,
        }
        : {
            path: "/merchant-details",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "MerchantQrCode",
            path: "/merchant-qr-code",
            component: MerchantQrCodeShow,
        }
        : {
            path: "/merchant-qr-code",
            redirect: "/no-permission",
        },

    //--------- Stamp Cards ------------//
    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "StampCards",
            path: "/stamp-cards",
            component: StampCardsView,
        }
        : {
            path: "/stamp-cards",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "NewStampCard",
            path: "/stamp-card",
            component: StampCardForm,
        }
        : {
            path: "/stamp-card",
            redirect: "/no-permission",
        },

    getUser()?.role === "ADMIN" || getUser()?.role === "MERCHANT"
        ? {
            name: "StampCardDetails",
            path: "/stamp-card-details",
            component: StampCardShow,
        }
        : {
            path: "/stamp-card-details",
            redirect: "/no-permission",
        },

    //--------- Account Routes ------------//
    {
        name: "AccountsDetails",
        path: "/account-details",
        component: AccountsView,
    },
];

export const merchantsRouter = merchantsRoutes.filter(function (x) {
    return x !== null;
});
