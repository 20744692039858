<template>
  <div>
    <div>
      <data-table
        :loader="loadData"
        :headers="headers"
        @done="$router.back()"
        :view-handler="userScopes.includes('users:view') ? view : null"
      >
        <template #firstname="{ item }">
          {{ item.firstname }}
        </template>

        <template #sendNotification="{ item }">
          <v-checkbox v-model="item.sendNotification"></v-checkbox>
        </template>

        <template #primary-action>
          <v-checkbox
            v-model="selectAll"
            color="primary"
            class="mt-1 mr-2"
            label="Select All"
            @click="selectAllUsers"
          ></v-checkbox>
          <v-btn
            class="ml-2"
            color="primary"
            elevation="0"
            outlined
            @click="sendNotifications"
          >
            Send Notification
          </v-btn>
        </template>
      </data-table>
    </div>

    <v-dialog v-model="notificationDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Send Notification</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="sendNotificationData.title"
            label="Title"
            outlined
          ></v-text-field>
          <v-textarea
            v-model="sendNotificationData.body"
            label="Body"
            outlined
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="notificationDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="sendNotifications"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Please wait..." />
  </div>
</template>

<script>
import { UsersService } from "@/services/user-service";
import DataTable from "../../components/DataTable";
import { getUserScopes } from "../../utils/local";
import LoadingDialog from "../../components/LoadingDialog";

export default {
  components: { DataTable, LoadingDialog },

  data: () => ({
    loading: false,
    items: [],
    users_service: new UsersService(),
    userScopes: getUserScopes(),
    selectAll: false,

    notificationDialog: false,
    sendNotificationData: {
      title: "",
      body: "",
    },

    headers: [
      {
        text: "First Name",
        value: "firstname",
        sortable: true,
      },
      {
        text: "Surname",
        value: "surname",
        sortable: true,
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },
      {
        text: "Account Type",
        value: "accountType",
        sortable: true,
      },
      {
        text: "Account Holder Type",
        value: "accountHolderType",
        sortable: true,
      },
      {
        text: "Send Notification",
        value: "sendNotification",
        sortable: false,
      },
    ],
  }),

  methods: {
    view(item) {
      this.$router.push(`/user-details?id=${item._id}`);
    },

    selectAllUsers() {
      this.items.forEach((item) => {
        item.sendNotification = this.selectAll;
      });
    },

    async sendNotifications() {
      if (!this.items.some((item) => item.sendNotification)) {
        this.$toast.error("Please select at least one user");
        return;
      }

      if (!this.notificationDialog) {
        this.notificationDialog = true;
        return;
      }

      if (!this.sendNotificationData.title || !this.sendNotificationData.body) {
        this.$toast.error("Please fill all fields");
        return;
      }

      this.loading = true;
      const users = this.items.filter((item) => item.sendNotification);
      let data = users.map((item) => item._id);
      data = {
        userIds: [...data],
        title: this.sendNotificationData.title,
        body: this.sendNotificationData.body,
      };
      data.title = data.title.replace(/[\uD800-\uDFFF]./g, "");
      data.body = data.body.replace(/[\uD800-\uDFFF]./g, "");
      await this.users_service
        .sendNotifications(data)
        .then(() => {
          this.$toast.success("Notification sent successfully");
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.sendNotificationData.title = "";
          this.sendNotificationData.body = "";
          this.selectAll = false;
          this.items.forEach((item) => {
            item.sendNotification = false;
          });

          this.notificationDialog = false;
          this.loading = false;
        });
    },

    async loadData() {
      let data = await this.users_service.fetchAll();
      data = data.map((item) => {
        item.sendNotification = false;
        return item;
      });

      this.items = data;
      return data;
    },
  },
};
</script>
