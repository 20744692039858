<template>
  <SimpleForm
      :is-edit="isEdit"
      :disabled="disabled"
      :onSubmit="submit"
      @done="$router.back()"
  >
    <v-row class="span-2" no-gutters>
      <v-btn @click="$router.back()" elevation="0">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn
      >

      <p class="form__title" style="width: 90%">
        {{ isEdit ? "Update Voucher Terms & Conditions" : "Add Voucher Terms & Conditions" }}
      </p>
    </v-row>

    <div class="span-2 mb-6 text-right">
      <v-btn class="add-terms-btn" elevation="0" @click="() => {vouchers.push({title: '', description: ''})}">
        <v-icon
            small
            dark
            class="mr-1"
        >
          mdi-plus-circle
        </v-icon>
          Add Terms
      </v-btn>
    </div>

    <div class="span-2 container" v-for="(voucher, index) in vouchers" :key="voucher._id">
      <div class="d-flex justify-end mb-4">
        <v-icon
            @click="() => {vouchers.splice(index, 1);}"
            color="primary"
            style="cursor: pointer"
        >
          mdi-close-circle-outline
        </v-icon>
      </div>
      <v-text-field
          v-model="voucher.title"
          :rules="[required('Clause must be provided')]"
          label="Clause"
          outlined
      />

      <v-textarea
          v-model="voucher.description"
          :rules="[required('Description must be provided')]"
          label="Description"
          :counter="180"
          :maxlength="180"
          auto-grow
          outlined
      />
    </div>

    <loading-dialog v-model="loading" message="Fetching Voucher Data"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from "../../components/Form";

import LoadingDialog from "../../components/LoadingDialog";
import {required} from "@/utils/validators";
import {MerchantsService} from "@/services/merchant-service";

export default {
  name: "VoucherTermsConditionForm",
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    merchant_service: new MerchantsService(),

    // only for edit
    disabled: false,
    voucherOriginal: null,
    vouchers: [],
  }),

  mounted() {
    this.loadVoucher();
  },

  methods: {
    required,

    async loadVoucher() {
      if (!this.$route.query.voucherId) return;
      this.loading = true;
      this.voucherOriginal = await this.merchant_service.fetchOneVoucher({
        voucherId: this.$route.query.voucherId,
        restaurantId: this.$route.query.restaurantId
      });

      this.vouchers = this.voucherOriginal?.voucherObject[0]?.tos || []
      if (this.vouchers.length > 0)
        this.isEdit = true;
      this.loading = false;
    },

    async submit(context) {
      context.changeLoadingMessage("Updating Voucher");
      try {
        //updating voucher
        const data = {
          voucherObject: {
            ...this.voucherOriginal.voucherObject[0],
            tos: [
              ...JSON.parse(JSON.stringify(this.vouchers))
            ]
          }
        }
        await this.merchant_service.updateVoucherTos(this.voucherOriginal.voucherObject[0]._id, data);
        return true;
      } catch (e) {
        context.reportError({
          title: "Error while updating Voucher",
          description: e.response
              ? e.response.data.message
              : "Something went wrong!",
        });
        return false;
      }
    },
  },
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.container {
  padding: 1rem 2rem 2rem;
  border-radius: 0.5rem;
  background-color: #fceee4;
  margin-bottom: 1rem;
}

.add-terms-btn {
  border: 2px solid #ff6e01 !important;
  color: #ff6e01 !important;
  background-color: #fff !important;
}
</style>
