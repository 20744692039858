import UpdatePasswordView from "@/views/updatePassword/UpdatePasswordView.vue";

export const passwordRoutes = [
  {
    name: "Update Password",
    path: "/update-password",
    component: UpdatePasswordView,
  },
];

export const passwordRouter = passwordRoutes.filter(function (x) {
  return x !== null;
});
