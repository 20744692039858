<template>
  <div>
    <v-row class="mb-4" v-if="tab === 0">
      <v-col cols="12" md="6">
        <v-card>
          <div style="display: flex; justify-content: center">
            <v-card-title class="text-center">Total Deposit</v-card-title>
          </div>
          <v-card-text class="text-center">
            <h1>
              <span style="color: #ff6e01">€</span>
              {{ restaurantProfile.totalDeposit || 0 }}
            </h1>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <div style="display: flex; justify-content: center">
            <v-card-title class="text-center">Current Balance</v-card-title>
          </div>
          <v-card-text class="text-center">
            <h1>
              <span style="color: #ff6e01">€</span>
              {{
                restaurantProfile.availableDeposit &&
                restaurantProfile.availableDeposit % 2 !== 0
                  ? restaurantProfile.availableDeposit.toFixed(2)
                  : restaurantProfile.availableDeposit || 0
              }}
            </h1>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card>
          <div style="display: flex; justify-content: center">
            <v-card-title class="text-center">Total Sales</v-card-title>
          </div>
          <v-card-text class="text-center">
            <h1>
              <span style="color: #ff6e01">€</span>
              {{ restaurantProfile.totalSales || 0 }}
            </h1>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card>
          <div style="display: flex; justify-content: center">
            <v-card-title class="text-center">Total Deduction</v-card-title>
          </div>
          <v-card-text class="text-center">
            <h1>
              <span style="color: #ff6e01">€</span>
              {{
                restaurantProfile.totalDeductions &&
                restaurantProfile.totalDeductions % 2 !== 0
                  ? restaurantProfile.totalDeductions.toFixed(2)
                  : restaurantProfile.totalDeductions || 0
              }}
            </h1>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="restaurantProfile.availableDeposit < 0" cols="12" md="4">
        <v-card>
          <div style="display: flex; justify-content: center">
            <v-card-title class="text-center">Pending Payments</v-card-title>
          </div>
          <v-card-text class="text-center">
            <h1>
              <span style="color: #ff6e01">€</span>
              {{
                restaurantProfile.availableDeposit &&
                restaurantProfile.availableDeposit % 2 !== 0
                  ? restaurantProfile.availableDeposit.toFixed(2)
                  : restaurantProfile.availableDeposit || 0
              }}
            </h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-container>
      <v-tabs v-model="tab">
        <v-tab v-for="item in tabItems" :key="item.id" @change="updatingTab()">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabItems" :key="item.id">
          <DataTable
            :headers="tab == 0 ? transactionHeaders : depositHeaders"
            :allow-add="false"
            :allow-deposit="tab === 1 && user.role === 'ADMIN'"
            :allow-invoice="tab === 0 ? true : false"
            @add-deposit="depositDialog = !depositDialog"
            @generate-invoice="invoiceDialog = !invoiceDialog"
            :edit-handler="null"
            :view-handler="null"
            :delete-handler="null"
            :loader="loadData"
            :key="dataTableKey"
          >
            <template #date="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
            </template>

            <template #time="{ item }">
              <span>{{ formatTime(item.createdAt) }}</span>
            </template>
          </DataTable>
        </v-tab-item>
      </v-tabs-items>

      <loading-dialog v-model="dataLoading" message="Please wait..." />
    </v-container>

    <v-dialog v-model="depositDialog" max-width="500px">
      <v-card>
        <v-card-text
          class="text-center py-6"
          style="font-size: 24px; font-weight: bold"
          >Deposit Form</v-card-text
        >
        <v-card-text>
          <v-form ref="depositForm" @submit.prevent="depositMoneyFn">
            <v-text-field
              label="Restaurant Name"
              v-model="restaurantProfile.restaurantName"
              outlined
              disabled
            ></v-text-field>
            <v-text-field
              label="Amount"
              v-model.number="depositMoney.depositObject.amount"
              type="number"
              outlined
              :rules="[
                required('Amount must be provided'),
                (v) => v >= 0 || 'Amount must be greater than 0',
              ]"
            ></v-text-field>
            <v-btn color="primary" type="submit">Submit</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="invoiceDialog" max-width="500px">
      <v-card>
        <v-card-text
          class="text-center py-6"
          style="font-size: 24px; font-weight: bold"
          >Invoice Form</v-card-text
        >
        <v-card-text>
          <v-form ref="invoiceForm" @submit.prevent="generateInvoice">
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDate"
                      label="Start Date"
                      readonly
                      v-on="on"
                      outlined
                      :rules="[required('Start Date must be provided')]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="startDateMenu = false"
                    :max="getCurrentDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDate"
                      label="End Date"
                      readonly
                      v-on="on"
                      outlined
                      :rules="[required('End Date must be provided')]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="endDateMenu = false"
                    :max="getCurrentDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-btn color="primary" type="submit">Generate</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div v-if="showConfirmationModal" class="confirmation-modal">
      <div class="modal-content">
        <h2>Confirmation !</h2>
        <p style="margin-top: 10px">
          Are you sure you want to deposit €{{
            depositMoney.depositObject.amount
          }}? This Process can not be undone.
        </p>
        <div class="modal-buttons">
          <v-row justify="center" align-content="space-around">
            <v-col cols="3">
              <v-btn
                color="#ff6e01"
                class="modal-button"
                dark
                rounded
                outlined
                @click="confirmDelete"
                >Yes</v-btn
              >
            </v-col>
            <v-col cols="3">
              <v-btn
                color="#ff6e01"
                class="modal-button"
                rounded
                dark
                @click="cancelDelete"
                >No</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <error-dialog v-model="error" :error="errorVal" />
  </div>
</template>

<script>
import { MerchantsService } from "../../services/merchant-service";
import DataTable from "../../components/DataTable";
import { getUserScopes } from "../../utils/local";
import { required } from "../../utils/validators";
import LoadingDialog from "../../components/LoadingDialog";
import ErrorDialog from "../../components/ErrorDialog";
import dayjs from "dayjs";
import { getUser } from "../../utils/local";

export default {
  components: { DataTable, LoadingDialog, ErrorDialog },

  mounted() {
    this.loadData;
  },

  data: () => ({
    user: getUser(),
    error: false,
    errorVal: {},
    merchants: [],
    merchant: {},
    restaurantId: null,
    showConfirmationModal: false,
    items: [],
    merchants_service: new MerchantsService(),
    userScopes: getUserScopes(),
    dataLoading: false,
    depositDialog: false,
    invoiceDialog: false,
    dataTableKey: 0,
    restaurantProfile: {},

    startDate: "",
    endDate: "",
    startDateMenu: false,
    endDateMenu: false,

    tab: 0,
    tabItems: [
      {
        id: 1,
        tab: "Transaction History",
        title: "Transaction History",
      },
      {
        id: 2,
        tab: "Deposit History",
        title: "Deposit History",
      },
    ],

    transactionHeaders: [
      {
        text: "Voucher Type",
        value: "voucherType",
        sortable: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: true,
      },
      {
        text: "Deduction",
        value: "deductedAmount",
        sortable: true,
      },
      {
        text: "Debit/Credit",
        value: "transactionType",
        sortable: true,
      },
      {
        text: "Current Balance",
        value: "availableDeposit",
        sortable: true,
      },
    ],
    depositHeaders: [
      {
        text: "Debited Amount",
        value: "amount",
        sortable: true,
      },

      {
        text: "Date",
        value: "date",
        sortable: true,
      },
      {
        text: "Time",
        value: "time",
        sortable: true,
      },
      {
        text: "Current Balance",
        value: "availableDeposit",
        sortable: true,
      },
    ],
    voucher: {},
    depositMoney: {
      restaurantId: "",
      depositObject: {
        amount: null,
      },
    },

    dateRange: [],
    voucherId: null,
  }),

  methods: {
    required,
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    formatTime(date) {
      return dayjs(date).format("HH:mm:ss");
    },
    getCurrentDate() {
      return new Date().toISOString();
    },

    updatingTab() {
      this.dataTableKey++;
    },

    depositMoneyFn() {
      if (this.$refs.depositForm.validate()) {
        this.depositDialog = false;
        this.showConfirmationModal = true;
      }
    },

    async generateInvoice() {
      if (this.$refs.invoiceForm.validate()) {
        this.invoiceDialog = false;
        this.dataLoading = true;

        this.startDate = new Date(this.startDate);
        this.endDate = new Date(this.endDate);

        this.startDate = dayjs(this.startDate).format("YYYY-MM-DD HH:mm:ss");
        this.endDate = dayjs(this.endDate).format("YYYY-MM-DD HH:mm:ss");

        const invoice = {
          restaurantId: this.restaurantId,
          startDate: this.startDate,
          endDate: this.endDate,
        };

        try {
          await this.merchants_service.generateInvoice(invoice).then(() => {
            this.startDate = "";
            this.endDate = "";
            this.dataLoading = false;
            this.dataTableKey++;
          });
        } catch (err) {
          this.dataLoading = false;
          if (err.response.status === 400) {
            this.error = true;
            this.errorVal = {
              title: "Data Not Found",
              description: err.response.data.message,
            };
          }
        }
      }
    },

    async confirmDelete() {
      this.showConfirmationModal = false;
      this.dataLoading = true;
      this.depositMoney.restaurantId = this.restaurantId;

      await this.merchants_service
        .depositMoney(this.depositMoney)
        .then(() => {
          this.dataTableKey++;
          this.$refs.depositForm.reset();
          this.dataLoading = false;
          this.depositDialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelDelete() {
      this.showConfirmationModal = false;
      this.depositDialog = true;
    },

    async loadData() {
      if (this.user?.role === "ADMIN") {
        this.restaurantId = this.$route.query.restaurantId;
      } else {
        const merchants = await this.merchants_service.fetchAll();
        this.merchants = merchants;
        this.merchants = this.merchants.filter(
          (merchant) => merchant.userId && merchant.userId._id === this.user._id
        );
        this.merchant = this.merchants[0];

        this.restaurantId = this.merchant._id;
      }

      if (this.voucher) {
        if (this.tab == 0) {
          const restaurantProfile = await this.merchants_service.fetchOne(
            this.restaurantId
          );
          this.restaurantProfile = restaurantProfile;

          const transactionHistory =
            await this.merchants_service.fetchOneTransactionHistory(
              this.restaurantId
            );

          transactionHistory.forEach((item) => {
            item.voucherType = item.voucherType ? item.voucherType : "-";
            item.deductedAmount = item.deductedAmount
              ? item.deductedAmount && item.deductedAmount.toFixed(2)
              : "-";
            item.availableDeposit =
              item.availableDeposit && item.availableDeposit.toFixed(2);
          });

          return transactionHistory;
        } else {
          const depositHistory =
            await this.merchants_service.fetchOneDepositHistory(
              this.restaurantId
            );

          depositHistory.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA;
          });

          return depositHistory;
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>

.confirmation-modal
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    z-index: 9999

.modal-content
    background-color: #fff
    padding: 20px
    height: 200px
    width: 400px
    border-radius: 10px
    text-align: center
</style>
