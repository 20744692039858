<template>
  <data-table
    :loader="loadData"
    :headers="items && items[0]?.email ? headers : headers1"
    @done="$router.back()"
    :view-handler="view"
    :key="items.length"
  >
  </data-table>
</template>

<script>
import { RedeemVouchersService } from "@/services/redeem-vouchers-service";
import DataTable from "../../components/DataTable";
import { getUser } from "../../utils/local";

export default {
  components: { DataTable },

  mounted() {
    this.loadData;
  },

  data: () => ({
    items: [],
    redeem_vouchers_service: new RedeemVouchersService(),

    merchants: [],
    merchant: {},

    headers: [
      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Title",
        value: "title",
        sortable: true,
      },
      {
        text: "Voucher Type",
        value: "voucherType",
        sortable: true,
      },
      {
        text: "Voucher Preference",
        value: "voucherPreference",
        sortable: true,
      },
    ],

    headers1: [
      {
        text: "Title",
        value: "title",
        sortable: true,
      },
      {
        text: "Voucher Type",
        value: "voucherType",
        sortable: true,
      },
      {
        text: "Voucher Preference",
        value: "voucherPreference",
        sortable: true,
      },
    ],
  }),

  methods: {
    getUser,

    view(item) {
      this.$router.push(`/redeem-voucher-details?id=${item.voucherId._id}`);
    },

    async loadData() {
      let data;
      if (this.getUser().role === "ADMIN") {
        data = await this.redeem_vouchers_service.fetchAll();
      } else {
        data = await this.redeem_vouchers_service.fetchMerchantVouchers(
          this.getUser()._id
        );
      }

      this.items = data
        .map((item) => {
          let title = item.voucherId?.title;
          let email = item.userId?.email || "";
          let voucherType = item.voucherId?.voucherType;
          let voucherPreference = item.voucherId?.voucherPreference;
          return { ...item, title, email, voucherType, voucherPreference };
        })
        .filter((item) => item.voucherId);
      return this.items;
    },
  },
};
</script>
